import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Co je invazní ekologie?" />
    <h1>Co je invazní ekologie?</h1>
    <p>Invazn&iacute; ekologie (nebo tak&eacute; ekologie invaz&iacute;) je obor zab&yacute;vaj&iacute;c&iacute; se studiem ekologick&yacute;ch invaz&iacute;. Invaze, intenzivn&iacute; &scaron;&iacute;řen&iacute; nepůvodn&iacute;ch druhů, negativně ovlivňuj&iacute; <Link to="/biogeografie/biodiverzita/">biodiverzitu</Link>, fungov&aacute;n&iacute; ekosyst&eacute;mů, ale i ekonomiku, lidsk&eacute; zdrav&iacute; a obživu.</p>
    <p>Mezi z&aacute;kladn&iacute; c&iacute;le invazn&iacute; ekologie patř&iacute;:</p>
    <ul>
    <li>identifikovat druhy, kter&eacute; jsou potenci&aacute;lně invazn&iacute; a zkoumat jejich vlastnosti, kter&eacute; pravděpodobnost invazn&iacute;ho chov&aacute;n&iacute; zvy&scaron;uj&iacute;</li>
    <li>studovat společenstva a biotopy a jejich n&aacute;chylnost k invaz&iacute;m a zkoumat jejich vlastnosti, kter&eacute; pravděpodobnost k invazi zvy&scaron;uj&iacute;</li>
    <li>ře&scaron;it důsledky invaz&iacute; pro ekonomiku, lidsk&eacute; a biodiverzitu</li>
    <li>vypracovat z&aacute;sady pro předch&aacute;zen&iacute; biologick&yacute;ch invaz&iacute; a metodiky likvidace nebezpečn&yacute;ch invazivn&iacute;ch druhů</li>
    </ul>
    <hr />
    <h2>Stručn&aacute; historie invazn&iacute; ekologie</h2>
    <p>Historie invaz&iacute; sah&aacute; až do poč&aacute;tků neolitick&eacute; revoluce, kdy začalo doch&aacute;zet k &uacute;mysln&eacute;mu i ne&uacute;mysln&eacute;mu zavl&eacute;k&aacute;n&iacute; druhů. S n&aacute;stupem mezikontinent&aacute;ln&iacute; dopravy a globalizačn&iacute;ch procesů začal b&yacute;t transport druhů mnohem rychlej&scaron;&iacute; a na vět&scaron;&iacute; vzd&aacute;lenosti.</p>
    <p>Historie invazn&iacute; ekologie jako vědy se zač&iacute;n&aacute; ps&aacute;t v roce 1958, kdy C.&nbsp;Elton, považov&aacute;n za zakladatele invazn&iacute; ekologie, vydal d&iacute;lo <em>The&nbsp;Ecology of&nbsp;Invasions by&nbsp;Animals and&nbsp;Plants</em>. Jako samostatn&yacute; obor se začala vyv&iacute;jet v druh&eacute; polovině 80.&nbsp;let 20.&nbsp;stolet&iacute;.</p>
<hr />
    <h2>Podt&eacute;mata invazn&iacute; ekologie</h2>
    <p>T&eacute;ma invazn&iacute; ekologie bylo rozčleněno do několika samostatn&yacute;ch, tematick&yacute;ch č&aacute;st&iacute;:</p>
    <div className="od-sm">
<hr />
    <h3><Link to="/biogeografie/invazni-ekologie/invaze-zavlecene-druhy-a-dalsi-terminologie/">Invaze, zavlečen&eacute; druhy...</Link></h3>
    <p>Kapitolka studijn&iacute;ch materi&aacute;lů o terminologii invazn&iacute; ekologie. Definice původn&iacute;ho a nepůvodn&iacute;ho druhu, invazn&iacute;ho procesu a tak d&aacute;le!</p>
  <hr />
    <h3><Link to="/biogeografie/invazni-ekologie/invazni-procesy-ve-svete-a-cr/">Invazn&iacute; procesy ve světě a ČR</Link></h3>
    <p>Pohled na biologickou invazi z glob&aacute;ln&iacute;ho, ale i lok&aacute;ln&iacute;ho měř&iacute;tka. Rychl&yacute; n&aacute;hled do obecn&yacute;ch patrnost&iacute; a na konkr&eacute;tn&iacute; př&iacute;klady.</p>
  <hr />
    <h3><Link to="/biogeografie/invazni-ekologie/faktory-invazivnosti-a-invazibility/">Faktory invazivnosti a invazibility</Link></h3>
    <p>Jak&eacute; faktory ovlivňuj&iacute; m&iacute;ru invazivnosti dan&eacute;ho druhu? A co podmiňuje n&aacute;chylnost společenstev k invazi? No jo, o tom už je holt tahle kapitola.</p>
<hr />
    </div>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Invazní ekologie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/ostrovni/zvlastnosti-bioty/"><button className="inv">&larr; Biogeografická pravidla</button></Link>
    <Link to="/biogeografie/invazni-ekologie/invaze-zavlecene-druhy-a-dalsi-terminologie/"><button className="inv">Invaze, zavlečené druhy... &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
